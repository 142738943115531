<template>
  <div class="full-area" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="clearAndGo()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
      :class="{
        'too-much-content': data.fields.questionSubtitle && data.fields.options.length > 4 && data.fields.questionImage
      }"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="contentx" :class="{
        'has-header-image': data.fields.questionImage,
        'too-much-content': data.fields.questionSubtitle && data.fields.options.length > 4 && data.fields.questionImage
      }">
      <div class="grid-wrapper delay-entry">
        <div
          :span="12"
          v-for="(item, key) in randomizedOptions"
          class="option-box"
          :key="item"
          :class="{
            'standoutx': form.selected === item || form.selected.includes(item),
            'disabled': form.selected.length && form.selected[0] === data.fields.noneOfTheAbove
          }"
          @click="toggleThis(item, key)"
        >{{item}}<i class="el-icon-check icon-inside"></i></div>
        <div
          :span="12"
          v-if="data.fields.noneOfTheAbove && data.fields.validAnswers > 1"
          class="option-box"
          :key="data.fields.noneOfTheAbove"
          :class="{
            'standoutx': form.selected === data.fields.noneOfTheAbove || form.selected.includes(data.fields.noneOfTheAbove),
            'disabled': form.selected.length && form.selected[0] !== data.fields.noneOfTheAbove
          }"
          @click="toggleThis(data.fields.noneOfTheAbove, 'noneOfTheAbove')"
        >{{data.fields.noneOfTheAbove}}<i class="el-icon-check icon-inside"></i></div>
      </div>
    </content>

    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        :align-left="validAnswers > 1"
        :disable-submit-button="!inputIsValid && validAnswers > 1"
        :show-submit="validAnswers > 1"
        :show-back-button="data.fields.showBackButton"
        :is-mandatory="data.fields.isMandatory"
        v-on:skip="skipQuestion()"
        v-on:previous="previousQuestion()"
        v-on:submit="submitQuestion()"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MultiTextSelection',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      randomizedFormula: [],
      randomizedOptions: [],
      randomizedSkip: '',
      form: {
        type: `multiTextSelection`,
        identifier: this.data.fields.title,
        question: this.data.fields.questionTitle,
        numberOfOptions: this.data.fields.options.length,
        options: this.data.fields.options,
        weighted: null,
        weightedMin: [],
        weightedMax: [],
        id: this.data.sys.id,
        selected: [],
        splitSelected: {},
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        },
        hubSpotField: this.data.fields.hubSpotField
      }
    }
  },

  computed: {
    validAnswers () {
      if (this.data.fields.validAnswers <= this.data.fields.options.length) {
        return this.data.fields.validAnswers
      } else {
        return 1
      }
    },
    inputIsValid () {
      if (this.form.selected.length >= 1 && this.form.selected.length <= this.validAnswers) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    randomize () {
      if (!this.data.fields.randomize) {
        this.randomizedOptions = this.data.fields.options
        this.randomizedFormula = this.data.fields.formula
        this.randomizedSkip = this.data.fields.skip
        return
      }
      // Randomize images and formulas so that they go together
      const options = this.data.fields.options
      var temp = []
      for (var i = 0; i < this.data.fields.options.length; i++) {
        temp.push(i)
      }
      temp.sort(function () { return 0.5 - Math.random() })
      this.randomizedOptions = []
      this.randomizedFormula = []
      var randomizedSkipArray = []

      if (this.data.fields.skip) {
        var explodedSkip = this.data.fields.skip.split(',')
      }

      temp.forEach(index => {
        this.randomizedOptions.push(this.data.fields.options[index])
        if (this.data.fields.formula) {
          this.randomizedFormula.push(this.data.fields.formula[index])
        }
        if (this.data.fields.skip) {
          randomizedSkipArray.push(explodedSkip[index])
        }
      })

      if (this.data.fields.skip) {
        randomizedSkipArray.push(explodedSkip[explodedSkip.length - 1])
        this.randomizedSkip = randomizedSkipArray.join(',')
      }
    },
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    clearAndGo () {
      this.feedback = ''
      this.emitNext()
    },
    emitNext () {
      if (!this.feedback) {
        if (this.data.fields.storeResponseLocally) {
          this.$store.commit('STORE_LOCAL_RESPONSE', { key: this.form.identifier, value: this.form.selected[0] })
        }
        let formClone = { ...this.form };
        if (this.data.fields.noneOfTheAbove && this.data.fields.validAnswers > 1) {
          formClone.options = this.data.fields.options.concat(this.data.fields.noneOfTheAbove);
        }
        this.$emit('next', formClone)
      }
    },
    skipQuestion () {
      this.form.selected = ['S99']
      this.next()
    },
    previousQuestion () {
      this.$emit('previous')
    },
    submitQuestion () {
      this.next()
    },
    toggleThis (item, key) {
      if (this.locked) {
        return
      }
      if (key === 'noneOfTheAbove') {
        if (this.form.selected.length && this.form.selected[0] === this.data.fields.noneOfTheAbove) {
          this.form.selected = []
          return
        }
        if (this.form.selected.length === 0) this.form.selected.push(this.data.fields.noneOfTheAbove)
        return
      } else {
        if (this.form.selected.length && this.form.selected[0] === this.data.fields.noneOfTheAbove) return
      }
      if (!this.form.selected.includes(item)) {
        if (this.form.selected.length < this.validAnswers) {
          this.form.selected.push(item)
          this.form.splitSelected[key] = 1
        }
      } else {
        this.form.selected = this.form.selected.filter(el => {
          this.form.splitSelected[key] = 0
          return el !== item
        })
      }
    },
    calculateScores () {
      this.locked = true
      let opt = this.data.fields.options
      if (this.data.fields.noneOfTheAbove && this.data.fields.validAnswers > 1) {
        opt = this.data.fields.options.concat(this.data.fields.noneOfTheAbove)
      }
      return this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.randomizedFormula,
        selected: this.form.selected,
        options: opt,
        maxAnswers: this.data.fields.validAnswers,
        skip: this.randomizedSkip
      }).then(async scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    },
    next () {
      if (this.inputIsValid) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      } else {
        return
      }
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    }
  },

  mounted () {
    this.randomize()
    if (this.data.fields.autoSkip) {
      this.skipQuestion()
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.inputIsValid && this.validAnswers === 1) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contentx {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 60px;
  align-items: center;
}
.option-box {
  font-size: 19px;
  display: block;
  width: 100%;
  background: $gray-light;
  padding: 0.4em 0.5em;
  text-align: left;
  margin-bottom: 8px;
  position: relative;
  border-radius: 5px;
  line-height: 1.1;
  transition: all 0.2s ease-in-out;
  &.disabled {
    opacity: 0.5;
  }

  @include breakpointHeight($square) {
    line-height: 1;
    font-size: 15px;
  }
  @include breakpoint($micro) {
    margin-bottom: 4px;
    line-height: 1;
    font-size: 14px;
  }
  @include breakpoint($tablet) {
    font-size: 1em;
  }
  @include breakpoint($desktop) {
    padding: 0.6em 0.5em;
    font-size: 17px;
  }
  @include breakpoint($air) {
    font-size: 18px;
  }
  &.standoutx {
    background-color: rgba($color-emphasis-alt, 0.2);
    animation: standOut 0.4s ease-in-out forwards;
    .icon-inside {
      opacity: 1;
      bottom: 0;
      top: 0;
      @include breakpoint($micro) {
        transform: scale(0.7);
      }
    }    
  }
}
.has-header-image {
  &.contentx {
    margin-bottom: 0;
    margin-top: 20px;
    &.too-much-content {
      @include breakpointHeight($square) {
        margin-top: 10px;
      }
    }
  }
  .option-box {
    @include breakpoint($micro) {
      padding: 0.3em 0.5em;
      font-size: 1em;
    }
  }
}
.grid-wrapper {
  max-width: 94%;
  @include breakpoint($tablet) {
    max-width: 60%;
    font-size: 20px;
  }
  @include breakpoint($desktop) {
    max-width: 94%;
  }
}
.question-header {
  @include breakpoint($desktop) {
    padding-right: 10px;
    padding-left: 10px;
  }
  &.too-much-content {
    @include breakpointHeight($square) {
      margin-bottom: 20px !important;
      transform: scale(0.7) !important;
    }
  }
}

.feedback {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.contentx {
  margin-bottom: 20px;
}
.full-area {
  margin-left: auto;
  margin-right: auto;
}
@keyframes standOut {
  0% { transform: scale(0.99) }
  20% { transform: scale(1.10) }
  100% { transform: scale(1.02) }
}

</style>
