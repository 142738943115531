var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-area",class:{'feedback': _vm.feedback}},[_c('FeedbackScreen',{attrs:{"data":_vm.data,"feedback":_vm.feedback},on:{"retry":function($event){return _vm.retry()},"next":function($event){return _vm.clearAndGo()}}}),_c('QuestionHeader',{class:{
      'too-much-content': _vm.data.fields.questionSubtitle && _vm.data.fields.options.length > 4 && _vm.data.fields.questionImage
    },attrs:{"data":_vm.data,"no-question-title":true}}),(_vm.data.fields.questionSubtitle)?_c('h2',{staticClass:"optional-question-title",class:{'question-subtitle-strong': _vm.data.fields.questionImage}},[_vm._v(_vm._s(_vm.data.fields.questionSubtitle))]):_vm._e(),_c('content',{staticClass:"contentx",class:{
      'has-header-image': _vm.data.fields.questionImage,
      'too-much-content': _vm.data.fields.questionSubtitle && _vm.data.fields.options.length > 4 && _vm.data.fields.questionImage
    }},[_c('div',{staticClass:"grid-wrapper delay-entry"},[_vm._l((_vm.randomizedOptions),function(item,key){return _c('div',{key:item,staticClass:"option-box",class:{
          'standoutx': _vm.form.selected === item || _vm.form.selected.includes(item),
          'disabled': _vm.form.selected.length && _vm.form.selected[0] === _vm.data.fields.noneOfTheAbove
        },attrs:{"span":12},on:{"click":function($event){return _vm.toggleThis(item, key)}}},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-check icon-inside"})])}),(_vm.data.fields.noneOfTheAbove && _vm.data.fields.validAnswers > 1)?_c('div',{key:_vm.data.fields.noneOfTheAbove,staticClass:"option-box",class:{
          'standoutx': _vm.form.selected === _vm.data.fields.noneOfTheAbove || _vm.form.selected.includes(_vm.data.fields.noneOfTheAbove),
          'disabled': _vm.form.selected.length && _vm.form.selected[0] !== _vm.data.fields.noneOfTheAbove
        },attrs:{"span":12},on:{"click":function($event){return _vm.toggleThis(_vm.data.fields.noneOfTheAbove, 'noneOfTheAbove')}}},[_vm._v(_vm._s(_vm.data.fields.noneOfTheAbove)),_c('i',{staticClass:"el-icon-check icon-inside"})]):_vm._e()],2)]),_c('footer',[_c('SkipQuestionButton',{attrs:{"location":'locate-bottom',"align-left":_vm.validAnswers > 1,"disable-submit-button":!_vm.inputIsValid && _vm.validAnswers > 1,"show-submit":_vm.validAnswers > 1,"show-back-button":_vm.data.fields.showBackButton,"is-mandatory":_vm.data.fields.isMandatory},on:{"skip":function($event){return _vm.skipQuestion()},"previous":function($event){return _vm.previousQuestion()},"submit":function($event){return _vm.submitQuestion()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }